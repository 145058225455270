import React from 'react';
import { ApiContextProvider } from './context/ApiContext';
import Home from './containers/home';
import GlobalStyles from './styles/globals'

const App = () => {
	return (
		<ApiContextProvider>
			<>
				<Home />
				<GlobalStyles />
			</>


		</ApiContextProvider>
	);
};

export default App;
