const configs = [
    {
        addressContract: "5DD4hD14z5GitPavR2nMsFHoiC4oadtA3BZRPLX2ahbHEPgm",
        bgHeader: '#1B1B1B',
        bgBtnHeader: '#282826',
        colorTextBtnHeader: '#fff',
        bgSection: '#f9f9f9',
        colorTitle: '#1B1B1B',
        colorSubTitle: '#1B1B1B',
        colorText: '#000',
        bgBtn: '#1B1B1B',
        colorTextBtn: '#fff',
        //About
        logo: 'img/bste.png',
        title: 'Douglas NFT',
    },

]
export default configs
