import React, { useContext, useState, useEffect } from 'react'
import * as S from './styles'
import Header from '../../containers/header'
import Dialog from '@mui/material/Dialog'
import WalletIcon from '@mui/icons-material/Wallet';
import WalletConnectPage from "../../containers/wallet/WalletConnectPage";
import {
  web3Enable,
  web3Accounts,
} from '@polkadot/extension-dapp'
import { formatBalance } from '@polkadot/util';
//import { Abi, ContractPromise } from '@polkadot/api-contract'
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types'
import { ApiPromise } from '@polkadot/api'
import { ContractPromise } from '@polkadot/api-contract'
import { ApiContext } from '../../context/ApiContext'
import ABI from '../../artifacts/lunesnft.json'
import { Keyring } from '@polkadot/api';
import config_ from '../../config/configs'
// Create a keyring instance
const keyring = new Keyring({ type: 'sr25519' });

import { Alert, Box, Button, CircularProgress, TextField } from '@mui/material'


const ntf_ = {
  tokenId: "",
  fileUrl: "",
  symbol: "",
  name: "",
  price: "",
  maxSupply: "",
  description: "",
  created: "",
  maxPerMint: "",
  totalMint: "",
  erc20Address: "",
}

const Home = () => {
  const { api, apiReady } = useContext(ApiContext)
  const [error, setError] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  // const [contract, setContract] = useState<ContractPromise>()
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState<InjectedAccountWithMeta>()
  const [contract, setContract] = useState<ContractPromise>()
  const [feeGas, setFeeGas] = useState("")
  const [nft, setNft] = useState(false)
  const [mintNft, setMintNFT] = useState(ntf_)
  const [balanceLunes, setBalanceLunes] = useState<string>('')
  const [userQtdMint, setUserQtdMint] = useState<string>('0')

  const [config, setConfig] = useState(config_[0]);
  const [tokenId, setTokenId] = useState("1");
  const [response, setResponse] = useState<unknown>('');

  const queryParameters = new URLSearchParams(window.location.search)


  useEffect(() => {
    let param_id: any = queryParameters.get("id")
    let address: any = queryParameters.get("address")
    if (param_id && address){
      setTokenId(param_id)
      let conf = config_.find(el=>el.addressContract == address);
      if(conf)
        setConfig(conf)
      
    }
    const getBalance = async () => {
      if (!api || !apiReady || !account) return

      const balanceL: any = await api.query.system.account(account?.address)

      setBalanceLunes(formatBalance(balanceL.data.free.toBn(), { decimals: 8 }))
    }

    getBalance()
  }, [api, apiReady, account])
  useEffect(() => {
    if (apiReady)
      setLoading(false)
  }, [apiReady])
  useEffect(() => {
    updateState()
  }, [contract])
  const updateState = () => {
    if (contract) detailsNFT()
    if (contract) myNFT()
    if (contract) feeNwtWork()
  }

  const sendTransaction = async () => {

    const qtdMyMint = Number(userQtdMint);
    const qtdPerMin = Number(mintNft.maxPerMint);
    if (qtdMyMint >= qtdPerMin) {
      setError("Limit per Min " + qtdPerMin)
      setAlert(true)
      return;
    }
    setLoading(true)
  
    try {   
      await sendMint()
    } catch (e) {
      setAlert(true)
      setError(e.message)
      console.log(e);
    }
    setLoading(false)
  }
  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  const getGasLimit = (api: ApiPromise) =>
    api.registry.createType(
      'WeightV2',
      api.consts.system.blockWeights['maxBlock']
    )

  const sendMint = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }
    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }

    setLoading(true)


    setLoading(true)
    let qtdMyMint = Number(userQtdMint);
    const gasLimit: any = getGasLimit(api)
    //Estimativa do gas 

    await timeout(2000);
    connectWalletHandler()
    setLoading(true)
    const { gasRequired }: any = await contract.query['payableMintImpl::mint'](
      account.address,
      {
        gasLimit,
        storageDepositLimit: 300000
      },
      tokenId,
      1
    )
    console.log('gasRequired', gasRequired.toString())

    await contract.tx['payableMintImpl::mint']({
      gasLimit: gasRequired,
      storageDepositLimit: null
    },
      tokenId,
      1)
      .signAndSend(account.address, (res) => {
        if (res.status.isInBlock) {
          console.log('in a block')
        }
        if (res.status.isFinalized) {
          console.log("Rest Sucesso", res.toHuman())
          console.log('finalized')
          setLoading(false)
          setError("")
          qtdMyMint++;
          setUserQtdMint("" + qtdMyMint)
          setSuccessMsg('Successfully creted token nft!')
          setAlert(true)
          detailsNFT()
          myNFT()

        }
      })

  }
  const feeNwtWork = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }
    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }

    const gasLimit: any = getGasLimit(api)
    //Estimativa do gas 
    const { gasRequired, storageDeposit, result }: any = await contract.query['payableMintImpl::mint'](
      account.address,
      {
        gasLimit,
        storageDepositLimit: 300000
      },
      tokenId,
      1

    )
    console.log('gasRequired', gasRequired.toString())
    console.log('storageDeposit', storageDeposit.toHuman())
    console.log('output fee', result.toHuman())
    console.log(storageDeposit.toHuman().Charge)
    if (result.isErr) {
      let error = ''
      if (result.asErr.isModule) {
        const dispatchError = api.registry.findMetaError(result.asErr.asModule)
        console.log('error', dispatchError.name)
        error = dispatchError.docs.length ? dispatchError.docs.concat().toString() : dispatchError.name
      } else {
        error = result.asErr.toString()
      }
      setError(error)
      return
    }
    setFeeGas(storageDeposit.toHuman().Charge)
  }
  const detailsNFT = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }

    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }
    setLoading(true)
    const gasLimit: any = getGasLimit(api)
    const { gasRequired, result, output }: any = await contract.query['payableMintImpl::tokenDetails'](
      account.address,
      {
        gasLimit,
      }, {
      tokenId: tokenId,
    }
    )
    console.log('gasRequired', gasRequired.toString())
    console.log('result', result)
    console.log('output', output)

    if (result.isErr) {
      setAlert(true)
      setError(result.asErr.toString())
      return
    }

    if (output) {
      const nfts = output.toHuman()
      console.log('Detalhes', nfts?.Ok?.Ok)
      setMintNFT(nfts?.Ok?.Ok)

    }
    setLoading(false)
  }
  //Lista NFT da carteira do usuário
  const myNFT = async () => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return
    }

    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }
    const gasLimit: any = getGasLimit(api)
    const { gasRequired, result, output }: any = await contract.query['payableMintImpl::tokenAccount'](
      account.address,
      {
        gasLimit,
      }, {
      page: "1",
    }
    )

    console.log('gasRequired', gasRequired.toString())
    console.log('result', result)
    console.log('output', output)

    if (result.isErr) {
      return
    }

    if (output) {
      const nfts = output.toHuman()
      let qtdMintThis = nfts?.Ok?.Ok?.find(el => el.tokenId == tokenId);
      if (!qtdMintThis)
        return;
      console.log(qtdMintThis)
      console.log("qtd", qtdMintThis.balance)
      setUserQtdMint(qtdMintThis.balance)
    }
  }

  const connectWalletHandler = async () => {

    setError('')
    setSuccessMsg('')
    if (!api || !apiReady) {
      return
    }

    const extensions = await web3Enable('Lunes NFT')

    /* check if wallet is installed */
    if (extensions.length === 0) {
      console.log("The user does not have any Substrate wallet installed")
      setError('The user does not have any Substrate wallet installed')
      setAlert(true)
      return
    }

    // set the first wallet as the signer (we assume there is only one wallet)
    api.setSigner(extensions[0].signer)

    const injectedAccounts = await web3Accounts()

    if (injectedAccounts.length > 0) {
      setAccount(injectedAccounts[0])
    }
    conectcontract()
  }
  //inicia o contrato
  const conectcontract = () => {
    if(!config) return;
    const contract = new ContractPromise(api, ABI, config.addressContract);
    setContract(contract)
  }

  const handleClose = () => {
    setLoading(false)
  }
  const handleAlertClose = () => {
    setAlert(false)
  }
  const bobyNFT = () => {
    return (
      <S.Container bgSection={config.bgSection}>
        <S.Content>
          <S.Wrapper>
            <S.Title colorTitle={config.colorTitle}> {mintNft.tokenId} - {mintNft.symbol}</S.Title>
            <S.Paragraph margin="8px 0 16px">{mintNft.description}</S.Paragraph>
            <S.Span colorText={config.colorText}>
              Quantity: {mintNft.maxSupply}
            </S.Span>
            <S.Span colorText={config.colorText} margin="8px 0 0">
              ID: {mintNft.tokenId}
            </S.Span>
            <S.Span colorText={config.colorText} margin="8px 0 0">
              <TextField style={{fontSize:22, color:"#000", fontWeight:"bold", textAlign:"center" }} label={"Address Contract"} disabled value={config.addressContract} fullWidth/>
            </S.Span>
          </S.Wrapper>

          <S.Wrapper>
            <img src={`https://cloudflare-ipfs.com/ipfs/${mintNft.fileUrl}`} />
            <S.SubTitle colorSubTitle={config.colorSubTitle}>
              {mintNft.tokenId} - {mintNft.symbol}
            </S.SubTitle>
            <S.Paragraph>
              <img src="img/edition.svg" />
              {mintNft.totalMint + "/ " + mintNft.maxSupply}
            </S.Paragraph>
            <S.Paragraph>
              <img src="img/edition.svg" />
              Limit Mint :
              {userQtdMint + "/ " + mintNft.maxPerMint}
            </S.Paragraph>
            <S.Span colorText={config.colorText} size="16px">
              Price: <S.Paragraph>{Number(mintNft.price?.replace(/,/g, '').trim()) / 100000000} LUNES</S.Paragraph>
            </S.Span>
            <S.Button
              bgBtn={config.bgBtn}
              colorTextBtn={config.colorTextBtn}
              onClick={sendTransaction}
            >
              Mint  <WalletIcon htmlColor={config.bgBtn} fontSize={'large'} />
            </S.Button>
            <S.Span colorText="#8D8D93" size="14px" margin="auto">
              Yout balance in Lunes: {balanceLunes} <br/> Fee: {feeGas}
            </S.Span>
          </S.Wrapper>
        </S.Content>
      </S.Container>
    )
  }
  return (
    <>
      <Dialog onClose={handleClose} open={loading}>
        <Box sx={{ width: '250px', height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      </Dialog>
      <Dialog onClose={handleAlertClose} open={alert}>
        <Box sx={{ width: '300px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {error ? (
            <Alert severity="error"> <S.Paragraph margin="0px 0 16px">{error}</S.Paragraph></Alert>
          ) : (
            <Alert severity="success"><S.Paragraph margin="0px 0 16px">{successMsg}</S.Paragraph></Alert>
          )}
        </Box>
        <S.Button
          bgBtn={config.bgBtn}
          colorTextBtn={config.colorTextBtn}
          onClick={handleAlertClose}
        >
          Close
        </S.Button>
      </Dialog>
      <Header
        click={connectWalletHandler}
        logo={config.logo}
        isReady={apiReady}
        account={account}
        bgHeader={config.bgHeader}
        bgBtnHeader={config.bgBtnHeader}
        colorTextBtnHeader={config.colorTextBtnHeader}
      />
      {!contract ? <WalletConnectPage /> : (<>{bobyNFT()}</>)}

    </>
  )
}

export default Home
