import React from 'react'
import * as S from './styles'

type HeaderProps = {
  bgHeader: string
  logo: string
  bgBtnHeader: string
  colorTextBtnHeader: string,
  isReady:boolean,
  account:any
  click: () => void
}
import WalletIcon from '@mui/icons-material/Wallet';

const Header = ({ ...props }: HeaderProps) => {
  return (
    <S.Header bgHeader={props.bgHeader}>
      <S.Nav>
        <S.ContentLogo>
          <img src={props.logo} />
        </S.ContentLogo>
        <S.Button            
          bgBtnHeader={props.bgBtnHeader}
          colorTextBtnHeader={props.colorTextBtnHeader}
          onClick={props.click}
        >
          {props.account?(<>Connected Lunes </>):(<>Connect Wallet   <WalletIcon htmlColor={props.colorTextBtnHeader} fontSize={'large'} /></>)} 
        </S.Button>
        <S.ButtonMobile        
          bgBtnHeader={props.bgBtnHeader}
          colorTextBtnHeader={props.colorTextBtnHeader}
          onClick={props.click}
        >
         <WalletIcon htmlColor={props.colorTextBtnHeader} fontSize={'large'} />
        </S.ButtonMobile>
      </S.Nav>
    </S.Header>
  )
}

export default Header
